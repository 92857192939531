import {Button} from "reactstrap";
import React from "react";

export function CButton(props) {
    const {title, loading, color, loadingColor, ...other} = props

    return (
        <Button color={color} disabled={loading} onClick={props.onClick}>
            {loading && <span>Please wait</span>}
            {loading && (
                <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                />
            )}

            {!loading && <span>{title}</span>}
        </Button>
    )
}