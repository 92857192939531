import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

export function CTextInput(props) {

    return (
        <FormGroup>
            <Label htmlFor={props.id}>
                {props.title}
            </Label>
            <Input
                className={`form-control ${props.error ? 'border-danger' : ''}`}
                type={props.type}
                name={props.name}
                id={props.id}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
            <small className="text-danger">{props.error}</small>
        </FormGroup>
    )
}

