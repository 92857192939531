import React from "react";

const NotFoundScreen = (props)=>{
    return(
        <div style={{alignSelf: 'center', width: '100%'}}>
            <img src={require('../../assets/images/nothing.png')}
                   style={{width: 200, height: 200, marginRight: '40%'}}/>
            <p style={{textAlign: 'center'}}>Not found</p>
        </div>
    )
}
export default NotFoundScreen