import React from "react";
import { Container, Col } from "reactstrap";
import { Settings } from "react-feather";
const Rightsidebar = (props) => {
  return (
    <div className="right-sidebar" id="right_side_bar">
      <div>
        <Container className="p-0">
          <div className="modal-header p-l-20 p-r-20">
            <Col sm="8" className="p-0">
              <h6 className="modal-title font-weight-bold">Contacts Status</h6>
            </Col>
            <Col sm="4" className="text-right p-0">
              <Settings className="mr-2" />
            </Col>
          </div>
        </Container>
        <div className="friend-list-search mt-0">
          <input type="text" placeholder="search friend" />
          <i className="fa fa-search"></i>
        </div>
      </div>
    </div>
  );
};

export default Rightsidebar;
