import {Api} from "../../routes";

export const AuthService = {
    login: (mobile, password) => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.post(api.route('login'), {mobile: mobile, password: password}).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    check: () => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.get(api.route('check')).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    changePassword: (password, newPassword) => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.post(api.route('change password'), {
            currentPassword: password,
            newPassword: newPassword
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

}
