import React, {Fragment, useEffect, useState} from "react";
import {
    Card, CardBody, CardHeader, Col,
    Container, Row, Table,
} from "reactstrap";
import Breadcrumb from "../components/common/breadcrumb/breadcrumb";
import {Link, withRouter} from "react-router-dom";
import {DashboardService} from "../api/actions/dashboard";
import swal from "sweetalert";
import Loader from "../components/common/loader/loader";
import NotFound from "../components/common/not-found";


const Dashboard = (props) => {

    const [activeUsers, setActiveUsers] = useState(0)
    const [jobs, setJobs] = useState(0)
    const [job, setJob] = useState()
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        DashboardService.dashboard().then(result => {
            setLoading(false)
            setJobs(result.data.jobs)
            setJob(result.data.job)
            setActiveUsers(result.data.activeUsers)
            setUsers(result.data.users)
        }).catch(error => {
            console.log(error)
            setLoading(false)
            swal({
                title: JSON.parse(JSON.stringify(error)).message,
                icon: "success",
                button: 'OK',
            }).then(() => {
                if (JSON.parse(JSON.stringify(error)).status === 403) {
                    props.history.push('/error/403')
                } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                    props.history.push('/error/401')
                } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                    props.history.push('/error/400')
                } else {
                    props.history.push('/error/500')
                }
            })
        })
    }, [])

    return (
        !loading ?
            <Fragment>
                <Breadcrumb parent="Admin Panel" title="Dashboard"/>
                <Container fluid={true}>
                    <Row>
                        <Col sm={4} md={3} lg={4}>
                            <Card>
                                <CardBody>
                                    <strong>All users</strong>
                                    <h3>{users.length} <small
                                        style={{fontSize: 10}}> Person </small></h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={4} md={3} lg={4}>
                            <Card>
                                <CardBody>
                                    <strong>Active users</strong>
                                    <h3>{activeUsers} <small
                                        style={{fontSize: 10}}> Person </small></h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={4} md={3} lg={4}>
                            <Card>
                                <CardBody>
                                    <strong>All jobs</strong>
                                    <h3>{jobs} <small
                                        style={{fontSize: 10}}> Job </small></h3>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {job && (
                        <Card>
                            <CardHeader>Active job</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4} md={3} lg={4}>
                                        <label>Job ID</label><br/>
                                        <strong className={'m-r-10'}
                                                style={{marginRight: '10px'}}>{job.id}</strong>
                                    </Col>
                                    <Col sm={4} md={3} lg={4}>
                                        <label>Start at</label><br/>
                                        <strong className={'m-r-10'}
                                                style={{marginRight: '10px'}}>{new Date(job.startAt).toLocaleDateString('en-US')}</strong>
                                    </Col>
                                </Row>

                                {job.notes.length ?
                                    <Card style={{marginTop: '10px'}}>
                                        <CardHeader>Notes</CardHeader>
                                        <CardBody>
                                            <div className="activity-table table-responsive">
                                                <Table borderless className={'table-hover'}>
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Message</th>
                                                        <th>Sent at</th>
                                                        <th>Sender</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {job.notes.map((note, index) => (
                                                        <tr>
                                                            <td>{++index}</td>
                                                            <td>{note.message}</td>
                                                            <td>{new Date(note.createdAt).toLocaleString('en-US')}</td>
                                                            <td> {note.user ? note.user.name : '-'} {note.user ? note.user.sureName : '-'}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    : null}

                                <Card style={{marginTop: '10px'}}>
                                    <CardHeader>QR Codes</CardHeader>
                                    <CardBody>
                                        {job.codes.length ?
                                            <div className="activity-table table-responsive">
                                                <Table borderless className={'table-hover'}>
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Code</th>
                                                        <th>Request at</th>
                                                        <th>Sent At</th>
                                                        <th>Delivered At</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {job.codes.map((code, index) => (
                                                        <tr>
                                                            <td>{++index}</td>
                                                            <td>{code.code}</td>
                                                            <td>
                                                                {new Date(code.requestedAt).toLocaleString('en-US')}
                                                                <br/>
                                                                {code.requestedUser ? code.requestedUser.name : '-'} {code.requestedUser ? code.requestedUser.sureName : '-'}
                                                            </td>
                                                            <td>
                                                                {code.sentAt ? new Date(code.sentAt).toLocaleString('en-US') : '---'}
                                                                <br/>
                                                                {code.sentUser ? code.sentUser.name : '-'} {code.sentUser ? code.sentUser.sureName : '-'}
                                                            </td>
                                                            <td>
                                                                {code.deleiveredAt ? new Date(code.deleiveredAt).toLocaleString('en-US') : '---'}
                                                                <br/>
                                                                {code.deliveredUser ? code.deliveredUser.name : '-'} {code.deliveredUser ? code.deliveredUser.sureName : '-'}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : <NotFound/>}
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>
                    )}

                </Container>
            </Fragment>
            : <Loader loading={loading}/>
    );
};

export default withRouter(Dashboard)
