import {withRouter} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../components/common/breadcrumb/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Label, Row} from "reactstrap";
import {CButton, CTextInput} from "../components/common/Custom";
import validator from "validator";
import {toast} from "react-toastify";
import {AuthService, UserService} from "../api";
import swal from "sweetalert";

const EditAdminProfile = (props) => {

    const [buttonLoading, setButtonLoading] = useState(false)
    const [admin, setAdmin] = useState(null)

    useEffect(()=>{
        const admin = JSON.parse(localStorage.getItem('admin'))
        setAdmin(admin.user)
        setFirstname(admin.user.name)
        setLastname(admin.user.sureName)
        setMobile(admin.user.mobile)
    }, [])


    const [firstname, setFirstname] = useState('')
    const [firstnameError, setFirstnameError] = useState('')
    const handleSetFirstname = event => {
        setFirstnameError('')
        if (validator.isEmpty(event.target.value))
            setFirstnameError('Name is required')
        else if (event.target.value.length > 255)
            setFirstnameError('Name is invalid');

        setFirstname(event.target.value)
    }

    const [lastname, setLastname] = useState('')
    const [lastnameError, setLastnameError] = useState('')
    const handleSetLastname = event => {
        setLastnameError('')
        if (validator.isEmpty(event.target.value))
            setLastnameError('Sure name is required')
        else if (event.target.value.length > 255)
            setLastnameError('Sure name is invalid');

        setLastname(event.target.value)
    }

    const [mobile, setMobile] = useState('')
    const [mobileError, setMobileError] = useState('')
    const handleSetMobile = event => {
        setMobileError('')
        if (validator.isEmpty(event.target.value))
            setMobileError('Mobile is required')

        setMobile(event.target.value)
    }


    const editAccount = event => {
        event.preventDefault()

        var goOn = true
        setButtonLoading(true)

        setFirstnameError('')
        setLastnameError('')
        setMobileError('')

        if (validator.isEmpty(firstname)) {
            setFirstnameError('Name is required')
            goOn = false
        } else if (firstname.length > 255) {
            setFirstnameError('Name is invalid');
            goOn = false
        }

        if (validator.isEmpty(lastname)) {
            setLastnameError('Sure name is required')
            goOn = false
        } else if (lastname.length > 255) {
            setLastnameError('Sure name is invalid');
            goOn = false
        }

        if (validator.isEmpty(mobile)) {
            setMobileError('Mobile is required')
            goOn = false
        }


        if (!goOn) {
            setButtonLoading(false)
        } else {

            UserService.update(admin.id, firstname, lastname, mobile, '').then(result => {
                if (result.success) {
                    swal({
                        title: result.message,
                        icon: "success",
                        button: 'OK'
                    })
                } else {
                    toast.error(result.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch(error => {
                setButtonLoading(false)
                if (JSON.parse(JSON.stringify(error)).status === 403) {
                    props.history.push('/error/403')
                } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                    props.history.push('/error/401')
                } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                    props.history.push('/error/400')
                } else {
                    props.history.push('/error/500')
                }
            })
        }
    }


    const [currentPassword, setCurrentPassword] = useState('')
    const [currentPasswordError, setCurrentPasswordError] = useState('')
    const handleSetCurrentPassword = event => {
        setCurrentPasswordError('')
        if (validator.isEmpty(event.target.value))
            setCurrentPasswordError('Current password required')

        setCurrentPassword(event.target.value)
    }

    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const handleSetNewPassword = event => {
        setNewPasswordError('')
        if (validator.isEmpty(event.target.value))
            setNewPasswordError('New password required')
        else if (!validator.isStrongPassword(event.target.value))
            setNewPasswordError('New password is not strong');

        setNewPassword(event.target.value)
    }

    const [reNewPassword, setReNewPassword] = useState('')
    const [reNewPasswordError, setReNewPasswordError] = useState('')
    const handleSetReNewPassword = event => {
        setReNewPasswordError('')
        if (validator.isEmpty(event.target.value))
            setReNewPasswordError('Re-new password required')
        else if (!validator.isStrongPassword(event.target.value))
            setReNewPasswordError('Re-new password is not strong');

        setReNewPassword(event.target.value)
    }

    const updatePassword = event => {
        event.preventDefault()

        var goOn = true
        setButtonLoading(true)

        setCurrentPasswordError('')
        setNewPasswordError('')
        setReNewPasswordError('')

        if (validator.isEmpty(currentPassword)) {
            setCurrentPasswordError('Current password required')
            goOn = false
        }

        if (validator.isEmpty(newPassword)) {
            setNewPasswordError('New password required')
            goOn = false
        }else if (!validator.isStrongPassword(newPassword)) {
            setNewPasswordError('New password is not strong');
            goOn = false
        }

        if (validator.isEmpty(reNewPassword)) {
            setNewPasswordError('Re-new password required')
            goOn = false
        }else if (!validator.isStrongPassword(reNewPassword)) {
            setNewPasswordError('Re-new password is not strong');
            goOn = false
        }

        if(!validator.isEmpty(reNewPassword) && !validator.isEmpty(newPassword)){
            if(newPassword !== reNewPassword){
                setReNewPasswordError('New password and confirmation is not match')
                goOn = false
            }
        }


        if (!goOn) {
            setButtonLoading(false)
        } else {

            AuthService.changePassword(currentPassword, newPassword).then(result => {
                if (result.success) {
                    swal({
                        title: result.message,
                        icon: "success",
                        button: 'OK',
                    })
                } else {
                    toast.error(result.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
                setButtonLoading(false)
            }).catch(error => {
                console.log(error)
                setButtonLoading(false)
                if (JSON.parse(JSON.stringify(error)).status === 403) {
                    props.history.push('/error/403')
                } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                    props.history.push('/error/401')
                } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                    props.history.push('/error/400')
                } else {
                    props.history.push('/error/500')
                }
            })
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent={'Admin Panel'} title={'Update profile'}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5>{'Update profile'}</h5>
                            </CardHeader>
                            <Form className="form theme-form"
                                  onSubmit={editAccount}>
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'firstname'}
                                                title={'Name'}
                                                onChange={handleSetFirstname}
                                                error={firstnameError}
                                                value={firstname}/>
                                        </Col>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'lastname'}
                                                title={'Sure name'}
                                                onChange={handleSetLastname}
                                                error={lastnameError}
                                                value={lastname}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'mobile'}
                                                title={'Mobile'}
                                                onChange={handleSetMobile}
                                                error={mobileError}
                                                value={mobile}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <CButton
                                        title={'Update'}
                                        color={'primary'}
                                        loading={buttonLoading}
                                        className={'btn-block'}/>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5>{'Change password'}</h5>
                            </CardHeader>
                            <Form className="form theme-form"
                                  onSubmit={updatePassword}>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <CTextInput
                                                type={'password'}
                                                id={'currentPassword'}
                                                title={'Current password'}
                                                onChange={handleSetCurrentPassword}
                                                error={currentPasswordError}
                                                value={currentPassword}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CTextInput
                                                type={'password'}
                                                id={'newPassword'}
                                                title={'New password'}
                                                onChange={handleSetNewPassword}
                                                error={newPasswordError}
                                                value={newPassword}/>
                                        </Col>
                                        <Col sm={6}>
                                            <CTextInput
                                                type={'password'}
                                                id={'reNewPassword'}
                                                title={'Re-new password'}
                                                onChange={handleSetReNewPassword}
                                                error={reNewPasswordError}
                                                value={reNewPassword}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <CButton
                                        title={'Change password'}
                                        color={'primary'}
                                        loading={buttonLoading}
                                        className={'btn-block'}/>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withRouter(EditAdminProfile)