import React from 'react';
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'prismjs/themes/prism.css';
import styled, {useTheme} from 'styled-components';
import {CMenu} from "../mui";
var PrismDecorator = require('draft-js-prism');
var Prism = require('prismjs')

var decorator = new PrismDecorator({
    // Provide your own instance of PrismJS
    prism: Prism,
});

const CEditorStyle = styled.div`
  background: #eeeeee;
  padding: 10px;
  border-radius: 7px;
  
  > .toolbar {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
    > .grow {
      flex-grow: 1;
    }
  }
  
  & .public-DraftEditor-content {
    min-height: 500px;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
  }
  
  & .public-DraftEditorPlaceholder-inner {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  
  & .public-DraftStyleDefault-pre {
    direction: ltr !important;
    text-align: left !important;
    background: #ffffff;
    padding: 10px 15px;
    
    & .public-DraftStyleDefault-block {
      text-align: left !important;
      direction: ltr !important;
    }
  }
  
  & blockquote {
    border-right: 3px solid #000000};
    padding: 1em 1em 1em 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`

const ToolbarButton = styled.span`
  padding: 5px 10px;
  background: #ffffff;
  color: #000000;
  border: none;
  outline: none;
  border-radius: 7px;
  font-size: 12px;
  transition: all .05s ease-in-out;
  font-weight: 300;
  margin-left: 5px;
  
  &.active {
    font-weight: 800;
  }
  
  &:hover {
    cursor: pointer;
    background: #ffffff;
    transition: all .05s ease-in-out;
  }
  
  &:last-child {
    margin-left: 0;
  }
`

const HelperText = styled.p`
  text-align: right !important;
  font-family: openSans !important;
  font-weight: 300 !important;
  font-size: 10px !important;
  color: #000000 !important;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
  margin: 3px 14px 0 14px !important;
`

function CEditor(props) {
    const theme = useTheme();
    const editorRef = React.useRef(null);

    const [editorState, setEditorState] = React.useState(() => EditorState.createWithContent(convertFromRaw(
        {
            entityMap: {},
            blocks: [
                {
                    text: "",
                    key: "foo",
                    type: "unstyled",
                    entityRanges: [],
                },
            ],
        }
    ), decorator))

    const [sizeAnchorEl, setSizeAnchorEl] = React.useState(null);
    const sizeMenuOpen = Boolean(sizeAnchorEl);
    const handleOpenSizeMenu = (event) => {
        setSizeAnchorEl(event.currentTarget);
    };
    const handleCloseSizeMenu = () => {
        setSizeAnchorEl(null);
    };

    const handleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style))
    }

    const handleBlockType = (type) => {
        setEditorState(RichUtils.toggleBlockType(editorState, type))
    }

    const styleMap = {
        CODE: {
            backgroundColor: '#ffffff',
            fontFamily: 'monospace',
            paddingLeft: 5,
            paddingRight: 5
        },
    };

    const handleChangeText = (newEditorState) => {
        setEditorState(newEditorState)

        if(props.onChange) {
            props.onChange(
                newEditorState.getCurrentContent().hasText(),
                convertToRaw(newEditorState.getCurrentContent()),
                newEditorState.getCurrentContent().getPlainText('\u0001')
            )
        }
    }

    return (
        <>
            <CEditorStyle
                minHeight={props.minHeight ?? 250}
            >
                <div className='toolbar'>
                    {props.showBlockTypes ?? true ?
                        <>
                            <ToolbarButton
                                onClick={handleOpenSizeMenu}
                                className={['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six'].includes(editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType()) ? 'active' : ''}
                            >
                                {
                                    editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-one' ? 'H1' :
                                        editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-two' ? 'H2' :
                                            editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-three' ? 'H3' :
                                                editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-four' ? 'H4' :
                                                    editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-five' ? 'H5' :
                                                        editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-six' ? 'H6' : 'H1'
                                }
                            </ToolbarButton>

                            <ToolbarButton
                                onClick={() => {handleBlockType('blockquote')}}
                                className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'blockquote' ? 'active' : ''}
                            >
                                Blockquote
                            </ToolbarButton>

                            <ToolbarButton
                                onClick={() => {handleBlockType('unordered-list-item')}}
                                className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'unordered-list-item' ? 'active' : ''}
                            >
                                UL
                            </ToolbarButton>

                            <ToolbarButton
                                onClick={() => {handleBlockType('ordered-list-item')}}
                                className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'ordered-list-item' ? 'active' : ''}
                            >
                                OL
                            </ToolbarButton>

                            <ToolbarButton
                                onClick={() => {handleBlockType('code-block')}}
                                className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'code-block' ? 'active' : ''}
                            >
                                {'< / >'}
                            </ToolbarButton>

                            <div className='grow' />
                        </>
                        :
                        null
                    }

                    <ToolbarButton
                        onClick={() => {handleInlineStyle('CODE')}}
                        className={editorState.getCurrentInlineStyle().has('CODE') ? 'active' : ''}
                    >
                        {'{ }'}
                    </ToolbarButton>

                    <ToolbarButton
                        onClick={() => {handleInlineStyle('STRIKETHROUGH')}}
                        className={editorState.getCurrentInlineStyle().has('STRIKETHROUGH') ? 'active' : ''}
                        style={{textDecoration: 'line-through'}}
                    >
                        T
                    </ToolbarButton>

                    <ToolbarButton
                        onClick={() => {handleInlineStyle('UNDERLINE')}}
                        className={editorState.getCurrentInlineStyle().has('UNDERLINE') ? 'active' : ''}
                        style={{textDecoration: 'underline'}}
                    >
                        T
                    </ToolbarButton>

                    <ToolbarButton
                        onClick={() => {handleInlineStyle('ITALIC')}}
                        className={editorState.getCurrentInlineStyle().has('ITALIC') ? 'active' : ''}
                        style={{fontStyle: 'italic'}}
                    >
                        I
                    </ToolbarButton>

                    <ToolbarButton
                        onClick={() => {handleInlineStyle('BOLD')}}
                        className={editorState.getCurrentInlineStyle().has('BOLD') ? 'active' : ''}
                    >
                        B
                    </ToolbarButton>
                </div>

                <CMenu
                    id="size-menu"
                    anchorEl={sizeAnchorEl}
                    open={sizeMenuOpen}
                    onClose={handleCloseSizeMenu}
                    style={{marginTop: 5}}
                >
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-one')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-one' ? 'active' : ''}
                    >
                        H1
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-two')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-two' ? 'active' : ''}
                    >
                        H2
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-three')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-three' ? 'active' : ''}
                    >
                        H3
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-four')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-four' ? 'active' : ''}
                    >
                        H4
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-five')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-five' ? 'active' : ''}
                    >
                        H5
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => {handleBlockType('header-six')}}
                        className={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'header-six' ? 'active' : ''}
                    >
                        H6
                    </ToolbarButton>
                </CMenu>

                <Editor
                    ref={editorRef}
                    editorState={editorState}
                    onChange={(newEditorState) => {handleChangeText(newEditorState)}}
                    textAlignment={props.textAlignment ?? 'right'}
                    textDirectionality={props.textDirectionality ?? 'RTL'}
                    placeholder={props.placeholder}
                    customStyleMap={styleMap}
                    userSelect="none"
                    contentEditable={false}
                />
                {props.value}
            </CEditorStyle>

            {props.helperText && props.helperText !== '' ?
                <HelperText>{props.helperText}</HelperText>
                :
                null
            }
        </>
    )
}

export default CEditor