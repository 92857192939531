import {withRouter} from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Table} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import PaginationList from "react-js-pagination";
import {toast} from "react-toastify";
import {CodeService} from "../../api/actions/code";
import NotFound from "../../components/common/not-found";
import Loader from "../../components/common/loader/loader";

const CodesList = (props) => {

    const [codes, setCodes] = useState([])
    const [count, setCount] = useState([])
    const [activePage, setActivePage] = React.useState(1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        CodeService.all(1).then(result => {
            setLoading(false)
            if (result.success) {
                setCodes(result.data.codes)
                setCount(result.data.count)
            } else {
                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(error => {
            setLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }, [])

    const handlePageChange = (pageNumber) => {
        setLoading(true)
        CodeService.all(pageNumber).then(result => {
            setLoading(false)
            if (result.success) {
                setCodes(result.data.codes)
                setCount(result.data.count)
                setActivePage(pageNumber)
            }
        }).catch(error => {
            setLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }

    return (
        !loading ?
            <Fragment>
                <Breadcrumb parent={'Admin Panel'} title={'QR Codes'}/>
                <Container fluid={true}>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <h5>{'QR Codes list'}</h5>
                                </CardHeader>
                                <CardBody>
                                    {codes.length ?
                                        <div className="activity-table table-responsive">
                                            <Table borderless className={'table-hover'}>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Code</th>
                                                    <th>Request at</th>
                                                    <th>Sent At</th>
                                                    <th>Delivered At</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {codes.map((code, index) => (
                                                    <tr>
                                                        <td>{++index}</td>
                                                        <td>{code.code}</td>
                                                        <td>
                                                            {new Date(code.requestedAt).toLocaleString('en-US')}
                                                            <br/>
                                                            {code.requestedUser ? code.requestedUser.name : '-'} {code.requestedUser ? code.requestedUser.sureName : '-'}
                                                        </td>
                                                        <td>
                                                            {code.sentAt ? new Date(code.sentAt).toLocaleString('en-US') : '---'}
                                                            <br/>
                                                            {code.sentUser ? code.sentUser.name : '-'} {code.sentUser ? code.sentUser.sureName : '-'}
                                                        </td>
                                                        <td>
                                                            {code.deleiveredAt ? new Date(code.deleiveredAt).toLocaleString('en-US') : '---'}
                                                            <br/>
                                                            {code.deliveredUser ? code.deliveredUser.name : '-'} {code.deliveredUser ? code.deliveredUser.sureName : '-'}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        : <NotFound/>}
                                </CardBody>
                                <CardFooter>
                                    <PaginationList
                                        activePage={activePage}
                                        itemsCountPerPage={20}
                                        totalItemsCount={count}
                                        pageRangeDisplayed={5}
                                        innerClass="pagination pagination-primary"
                                        activeClass="active"
                                        activeLinkClass="page-link"
                                        linkClass="page-link"
                                        itemClass="page-item"
                                        disabledClass="disabled"
                                        onChange={handlePageChange.bind(this)}
                                    />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
            :
            <Loader loading={loading}/>
    )
}

export default withRouter(CodesList)