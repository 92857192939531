import {Menu} from "@mui/material";
import styled from "styled-components";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const CMenuStyle = styled(Menu)`
  && .MuiPaper-root {
    box-shadow: none;
    border-radius: 10px;
    background: #ffffff;
  }
  
  && .MuiList-root {
    margin: 0;
    padding: 5px;
    
    > li {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      font-family: openSans;
      border-radius: 10px;
      transition: all .05s ease-in-out;
      
      &:hover {
        background: #ffffff;
        transition: all .05s ease-in-out;
      }
    }
  }
  
  && a {
    color: #000000;
  }
`

const CMenuItemStyle = styled(MenuItem)`
`

export function CMenu(props) {
    const {id, anchorOriginVertical, anchorOriginHorizontal, transformOriginVertical, transformOriginHorizontal, ...other} = props

    return (
        <CMenuStyle
            id={id}
            {...other}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: anchorOriginVertical ?? 'bottom',
                horizontal: anchorOriginHorizontal ?? 'right',
            }}
            transformOrigin={{
                vertical: transformOriginVertical ?? 'top',
                horizontal: transformOriginHorizontal ?? 'right',
            }}
        >
            {props.children}
        </CMenuStyle>
    )
}

export function CMenuItem(props) {
    const {children, ...other} = props

    return (
        <CMenuItemStyle
            disableTouchRipple={true}
            {...other}
        >
            {children}
        </CMenuItemStyle>
    )
}