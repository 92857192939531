import {withRouter} from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Row, Table} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {CodeService} from "../../api/actions/code";
import {CTextInput, CButton} from "../../components/common/Custom";
import {Workbook} from "exceljs";

const CodesSearch = (props) => {

    const [codes, setCodes] = useState([])

    const [buttonLoading, setButtonLoading] = useState(false)

    const [code, setCode] = useState('')
    const handleSetCode = event => {
        setCode(event.target.value)
    }

    const [fromDate, setFromDate] = useState('')
    const handleSetFromDate = event => {
        setFromDate(event.target.value)
    }

    const [toDate, setToDate] = useState('')
    const handleSetToDate = event => {
        setToDate(event.target.value)
    }

    const searchCodes = event => {
        event.preventDefault()

        setButtonLoading(true)
        CodeService.search(code, fromDate, toDate).then(result => {
            setButtonLoading(false)
            if (result.success) {
                setCodes(result.data)
            } else {
                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(error => {
            setButtonLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }

    const exportSearch = event => {
        event.preventDefault()

        setButtonLoading(true)
        CodeService.search(code, fromDate, toDate).then(result => {
            setButtonLoading(false)
            if (result.success) {

                const workbook = new Workbook
                const worksheet = workbook.addWorksheet('transactions')
                worksheet.columns = [
                    {header: 'Code', key: 'code', width: 10},
                    {header: 'Request At', key: 'requestedAt', width: 25},
                    {header: 'Sent At', key: 'sentAt', width: 20},
                    {header: 'Delivered At', key: 'deliveredAt', width: 30},
                    {header: 'Request User name', key: 'requestedUserName', width: 30},
                    {header: 'Request User sure name', key: 'requestedUserSureName', width: 30},
                    {header: 'Sent User name', key: 'sentUserName', width: 30},
                    {header: 'Sent User sure name', key: 'sentUserSureName', width: 30},
                    {header: 'Delivered User name', key: 'deliveredUserMame', width: 30},
                    {header: 'Delivered User sure name', key: 'deliveredUserSureName', width: 30},
                ];

                result.data.map((val) => {
                    worksheet.addRow({
                        code: val.code,
                        requestedAt: new Date(val.requestedAt).toLocaleString('en-US'),
                        sentAt: new Date(val.sentAt).toLocaleString('en-US'),
                        deliveredAt: new Date(val.deliveredAt).toLocaleString('en-US'),
                        requestedUserName: val.requestedUser ? val.requestedUser.name : '---',
                        requestedUserSureName: val.requestedUser ? val.requestedUser.sureName : '---',
                        sentUserName: val.sentUser ? val.sentUser.name : '---',
                        sentUserSureName: val.sentUser ? val.sentUser.sureName : '---',
                        deliveredUserMame: val.deliveredUser ? val.deliveredUser.name : '---',
                        deliveredUserSureName: val.deliveredUser ? val.deliveredUser.sureName : '---',
                    })
                })

                workbook.xlsx.writeBuffer().then(function (data: Blob) {
                    const blob = new Blob([data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = 'qr-codes-' + new Date().toLocaleString() + '.xls';
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                });

            } else {
                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(error => {
            setButtonLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }

    const exportAll = event => {
        event.preventDefault()

        setButtonLoading(true)
        CodeService.allList().then(result => {
            setButtonLoading(false)
            if (result.success) {

                const workbook = new Workbook
                const worksheet = workbook.addWorksheet('transactions')
                worksheet.columns = [
                    {header: 'Code', key: 'code', width: 10},
                    {header: 'Request At', key: 'requestedAt', width: 25},
                    {header: 'Sent At', key: 'sentAt', width: 20},
                    {header: 'Delivered At', key: 'deliveredAt', width: 30},
                    {header: 'Request User name', key: 'requestedUserName', width: 30},
                    {header: 'Request User sure name', key: 'requestedUserSureName', width: 30},
                    {header: 'Sent User name', key: 'sentUserName', width: 30},
                    {header: 'Sent User sure name', key: 'sentUserSureName', width: 30},
                    {header: 'Delivered User name', key: 'deliveredUserMame', width: 30},
                    {header: 'Delivered User sure name', key: 'deliveredUserSureName', width: 30},
                ];

                result.data.map((val) => {
                    worksheet.addRow({
                        code: val.code,
                        requestedAt: new Date(val.requestedAt).toLocaleString('en-US'),
                        sentAt: new Date(val.sentAt).toLocaleString('en-US'),
                        deliveredAt: new Date(val.deliveredAt).toLocaleString('en-US'),
                        requestedUserName: val.requestedUser ? val.requestedUser.name : '---',
                        requestedUserSureName: val.requestedUser ? val.requestedUser.sureName : '---',
                        sentUserName: val.sentUser ? val.sentUser.name : '---',
                        sentUserSureName: val.sentUser ? val.sentUser.sureName : '---',
                        deliveredUserMame: val.deliveredUser ? val.deliveredUser.name : '---',
                        deliveredUserSureName: val.deliveredUser ? val.deliveredUser.sureName : '---',
                    })
                })

                workbook.xlsx.writeBuffer().then(function (data: Blob) {
                    const blob = new Blob([data],
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = 'qr-codes-' + new Date().toLocaleString() + '.xls';
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                });

            } else {
                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(error => {
            setButtonLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent={'Admin Panel'} title={'QR Codes Search'}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>

                        <Card>
                            <CardHeader>
                                <h5>{'QR Codes search'}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <CTextInput
                                            id={'code'}
                                            title={'Code'}
                                            onChange={handleSetCode}
                                            error={''}
                                            value={code}/>
                                    </Col>
                                    <Col sm={4}>
                                        <CTextInput
                                            type={'datetime-local'}
                                            id={'fromDate'}
                                            title={'From Date'}
                                            onChange={handleSetFromDate}
                                            error={''}
                                            value={fromDate}/>
                                    </Col>
                                    <Col sm={4}>
                                        <CTextInput
                                            type={'datetime-local'}
                                            id={'toDate'}
                                            title={'To Date'}
                                            onChange={handleSetToDate}
                                            error={''}
                                            value={toDate}/>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter style={{flexDirection: 'row', display: 'flex'}}>
                                <CButton
                                    onClick={searchCodes}
                                    title={'Search'}
                                    color={'primary'}
                                    loading={buttonLoading}
                                    className={'btn-block'}/>

                                <div style={{margin: '10px'}}></div>
                                <CButton
                                    onClick={exportSearch}
                                    title={'Export searching codes'}
                                    color={'info'}
                                    loading={buttonLoading}
                                    className={'btn-block'}/>

                                <div style={{margin: '10px'}}></div>
                                <CButton
                                    onClick={exportAll}
                                    title={'Export all codes'}
                                    color={'warning'}
                                    loading={buttonLoading}
                                    className={'btn-block'}/>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5>{'QR Codes list'}</h5>
                            </CardHeader>
                            <CardBody>
                                {codes.length ?
                                    <div className="activity-table table-responsive">
                                        <Table borderless className={'table-hover'}>
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Code</th>
                                                <th>Request at</th>
                                                <th>Sent At</th>
                                                <th>Delivered At</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {codes.map((code, index) => (
                                                <tr>
                                                    <td>{++index}</td>
                                                    <td>{code.code}</td>
                                                    <td>
                                                        {new Date(code.requestedAt).toLocaleString('en-US')}
                                                        <br/>
                                                        {code.requestedUser ? code.requestedUser.name : '-'} {code.requestedUser ? code.requestedUser.sureName : '-'}
                                                    </td>
                                                    <td>
                                                        {code.sentAt ? new Date(code.sentAt).toLocaleString('en-US') : '---'}
                                                        <br/>
                                                        {code.sentUser ? code.sentUser.name : '-'} {code.sentUser ? code.sentUser.sureName : '-'}
                                                    </td>
                                                    <td>
                                                        {code.deleiveredAt ? new Date(code.deleiveredAt).toLocaleString('en-US') : '---'}
                                                        <br/>
                                                        {code.deliveredUser ? code.deliveredUser.name : '-'} {code.deliveredUser ? code.deliveredUser.sureName : '-'}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    : null}
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withRouter(CodesSearch)