import {Api} from "../../routes";

export const CodeService = {
    all: (page=1) => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.get(api.route('codes list', {page: page})).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    search: (code, fromDate, toDate) => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.post(api.route('codes search'), {
            code: code,
            fromDate: fromDate,
            toDate: toDate
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    allList: () => new Promise((resolve, reject) => {
        const api = new Api();
        api.instance.get(api.route('codes all list')).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),
}
