import React from "react";
import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {ReactComponent as AddImageIcon} from '../../../assets/images/svg/add-image.svg';
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/close.svg';
import {ReactComponent as DeleteImageIcon} from '../../../assets/images/svg/trash-can.svg';
import {ReactComponent as FileCheckIcon} from '../../../assets/images/svg/file-check.svg';
import DropZone from "../../DropZone";
import {CTooltip} from "../mui";
import 'cropperjs/dist/cropper.min.css';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
`

const NewCrop = styled.button`
  aspect-ratio: 1;
  border-radius: 15px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex: 1;
  transition: all .05s ease-in-out;
  
  > img {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: block;
  }
  > svg {
    width: 24px;
    height: 24px;
    fill: #000000;
    margin: 0 auto;
  }
  
  &:hover {
    cursor: pointer;
    background: #ffffff;
    transition: all .05s ease-in-out;
  }
`

const NewCropImage = styled.div`
  aspect-ratio: 1;
  border-radius: 15px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  position: relative;
  
  > img {
    width: 100%;
    aspect-ratio: 1;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  > svg {
    width: 24px;
    height: 24px;
    fill: #000000;
    margin: 0 auto;
  }
  
  > .hover-delete {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex: 1;
    opacity: 0;
    transition: all .05s ease-in-out;
    
    > svg {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 auto;
      flex-grow: 1;
      fill: #000000
    }
  }
  &:hover {
    cursor: pointer;
    
    > .hover-delete {
      opacity: 1;
      transition: all .05s ease-in-out;
    }
  }
`

const AddImageModal = styled.div`
  width: 600px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  
  > .header {
    padding: 15px;
    text-align: right;
    border-bottom: 1px solid #000000;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    align-content: center;
    
    > .title {
      font-family: openSans;
      font-size: 14px;
      font-weight: 800;
      color: #000000;
      padding: 0;
      margin: 0;
      flex-grow: 1;
    }
    
    .close {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      padding: 0;
      background: transparent;
      border: none;
      
      > svg {
        fill: #000000
      }
      
      &:hover {
        cursor: pointer;
        > svg {
          fill: #000000
        }
      }
    }
  }
  
  > .body {
    padding: 15px;
  }
`

function FileSelect(props) {

    const {maximum, maxSize, theme, files, setFiles, ...other} = props

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false)
    };

    const handleSelectFile = (file) => {
        var goOn = true;

        if (maxSize) {
            if (file.size > maxSize * 1024 * 1024) {
                goOn = false
                if (props.setGlobalSnackbar) {
                    props.setGlobalSnackbar(['error', {size: maxSize}])
                }
            }
        }

        if (goOn) {
            let newFiles = files
            newFiles.push({file: file, id: Math.round(Math.random() * 10000)})

            setFiles(newFiles)
            handleCloseModal()
        }
    }

    const handleDeleteFile = (id) => {
        let oldFiles = files
        let newFiles = []

        oldFiles.map((file) => {
            if (file.id !== id) {
                newFiles.push(file)
            }
        })

        setFiles(newFiles)
    }

    return (
        <Wrapper>
            {files.map((file, index) => (
                <CTooltip key={index} title={file.file.name}>
                    <NewCropImage onClick={() => {
                        handleDeleteFile(file.id)
                    }}>
                        <div className='hover-delete'>
                            <DeleteImageIcon/>
                        </div>
                        {['image/png', 'image/jpg', 'image/jpeg'].includes(file.file.type) ?
                            <img src={URL.createObjectURL(file.file)}/>
                            :
                            <FileCheckIcon/>
                        }
                    </NewCropImage>
                </CTooltip>
            ))}
            {files.length < maximum ?
                <NewCrop type={'button'} onClick={handleOpenModal}>
                    <AddImageIcon/>
                </NewCrop>
                :
                null
            }

            <Modal
                aria-labelledby="add-image-modal"
                aria-describedby="add-image-modal"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{overflowY: 'scroll'}}
            >
                <Fade in={openModal}>
                    <AddImageModal>
                        <div className='header'>
                            <h5 className='title'>
                                فایل جدید را انتخاب نمایید
                            </h5>
                            <button className='close' onClick={handleCloseModal}>
                                <CloseIcon/>
                            </button>
                        </div>
                        <div className='body'>
                            <DropZone
                                theme={theme}
                                callback={(file) => {
                                    handleSelectFile(file[0])
                                }}
                            />
                        </div>
                    </AddImageModal>
                </Fade>
            </Modal>
        </Wrapper>
    )
}

export default FileSelect