import axios, {AxiosInstance, AxiosResponse} from 'axios';
import cookie from "js-cookie";
import React from "react";

export class HttpClient {
    constructor(baseURL) {
        this.instance = axios.create({
            baseURL,
            withCredentials: true
        });

        this.instance.interceptors.request.use(
            config => {
                config.headers.authorization = localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : '';
                return config
            },
            error => {
                return Promise.reject(error)
            }
        );

        this._initializeResponseInterceptor();
    }

    _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    _handleResponse = ({data}) => data;

    _handleError = (error) => Promise.reject(error);
}
