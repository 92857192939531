import {Link, withRouter} from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Table} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {UserService} from "../../api";
import Loader from "../../components/common/loader/loader";

const UserList = (props) => {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        UserService.all(1).then(result => {
            setLoading(false)
            if (result.success) {
                setUsers(result.data)
            } else {
                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(error => {
            setLoading(false)
            if (JSON.parse(JSON.stringify(error)).status === 403) {
                props.history.push('/error/403')
            } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                props.history.push('/error/401')
            } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                props.history.push('/error/400')
            } else {
                props.history.push('/error/500')
            }
        })
    }, [])


    return (
        !loading ?
            <Fragment>
                <Breadcrumb parent={'Admin Pane;'} title={'Users'}/>
                <Container fluid={true}>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <h5>{'Users'}</h5>
                                </CardHeader>
                                <CardBody>
                                    {users.length ?
                                        <div className="activity-table table-responsive">
                                            <Table borderless className={'table-hover'}>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Sure name</th>
                                                    <th>Mobile</th>
                                                    <th>Is active</th>
                                                    <th>Role</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {users.map((user, index) => (
                                                    <tr>
                                                        <td>{++index}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.sureName}</td>
                                                        <td>{user.mobile}</td>
                                                        <td>{user.isActive ? 'Active' : 'Deactivated'}</td>
                                                        <td>{user.role}</td>
                                                        <td>
                                                            <Link to={`/users/${user.id}/edit`} className="btn btn-sm btn-info">Update</Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
            :
            <Loader loading={loading}/>
    )
}

export default withRouter(UserList)