import React, {Fragment, useEffect} from "react";
import Loader from "./components/common/loader/loader";
import Header from "./components/common/header/header";
import Sidebar from "./components/common/sidebar/sidebar";
import Rightsidebar from "./components/common/sidebar/rightsidebar";
import ThemeCustomize from "./components/common/theme-customizer/themeCustomize";
import "./App.scss"
import {Redirect, Route} from "react-router-dom";

const App = ({children}) => {
    if (localStorage.getItem('token'))
        return (
            <Fragment>
                <Loader/>
                <div className="page-wrapper">
                    <div className="page-body-wrapper">
                        <Header/>
                        <Sidebar/>
                        <Rightsidebar/>
                        <div className="page-body">{children}</div>
                        <ThemeCustomize/>
                    </div>
                </div>

            </Fragment>
        );
    else
        return <Route path='*' render={() =>
            (
                <Redirect to="/login"/>
            )
        }/>
};

export default App
