import React from "react";
import {
    Container,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
} from "reactstrap";
import validator from "validator";
import {withRouter} from "react-router-dom";
import {CButton, CTextInput} from "../../components/common/Custom";
import {AuthService} from "../../api";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert"

function Login(props) {

    const [buttonLoading, setButtonLoading] = React.useState(false)
    const [mobile, setMobile] = React.useState('')
    const [mobileError, setMobileError] = React.useState('')
    const handleSetMobile = (event) => {
        setMobileError('')

        if (validator.isEmpty(event.target.value)) {
            setMobileError('Mobile is required')
        }

        setMobile(event.target.value)
    }

    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')
    const handleSetPassword = (event) => {
        setPasswordError('')

        if (validator.isEmpty(event.target.value)) {
            setPasswordError('Password is required')
        }

        setPassword(event.target.value)
    }

    const loginAdmin = event => {
        event.preventDefault()

        setButtonLoading(true)
        let goOn = true
        setMobileError("")
        setPasswordError((""))

        if (validator.isEmpty(mobile)) {
            setMobileError('Mobile is required')
            goOn = false;
        }

        if (validator.isEmpty(password)) {
            setPasswordError('Password is required')
            goOn = false;
        }

        if (!goOn) {
            setButtonLoading(false)
        } else {
            AuthService.login(mobile, password).then(result => {
                setButtonLoading(false)
                if (result.success) {
                    swal({
                        title: result.message,
                        icon: "success",
                        button: 'OK',
                    }).then(() => {

                        localStorage.setItem('admin', JSON.stringify(result.data))
                        localStorage.setItem('token', result.data.token)

                        props.history.push(`/dashboard`);
                    })
                } else {
                    toast.error(result.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch(error => {
                setButtonLoading(false)
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            })
        }
    }

    return (
        <div className="page-wrapper">
            <Container className="p-0">
                <div className="authentication-main m-0">
                    <Row>
                        <Col md="12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <CardBody className="h-100-d-center">
                                        <div className="cont  b-light" style={{height: 'auto'}}>
                                            <div>
                                                <Form className="form theme-form"
                                                      onSubmit={loginAdmin}>

                                                    <h4 className="text-center">{'Login to account'}</h4>
                                                    <h6 className="text-center">{'Please enter mobile and password'}</h6>
                                                    <div className="text-right">

                                                        <CTextInput
                                                            title={'Mobile'}
                                                            name={'mobile'}
                                                            id={'mobile'}
                                                            onChange={handleSetMobile}
                                                            value={mobile}
                                                            error={mobileError}
                                                            type={'tel'}/>

                                                        <CTextInput
                                                            title={'Password'}
                                                            name={'password'}
                                                            id={'password'}
                                                            onChange={handleSetPassword}
                                                            value={password}
                                                            error={passwordError}
                                                            type={'password'}/>

                                                        <FormGroup className="form-row mt-3 mb-0">
                                                            <CButton
                                                                title={'Login'}
                                                                color={'primary'}
                                                                loading={buttonLoading}
                                                                className={'btn-block'}/>
                                                        </FormGroup>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}


export default withRouter(Login)
