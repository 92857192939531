import {ADMIN} from "../actionType";

const initialAdmin = {}

export const AdminReducer = (options = initialAdmin, {type, payload}) => {
    switch (type) {
        case ADMIN:
            return payload
        default:
            return options;
    }
}