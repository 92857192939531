import React, {Fragment, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import store from "./store/index";
import {Provider} from "react-redux";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import App from "./App";
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {routes} from "./routes";
import ScrollToTop from "./components/common/ScrollToTop";
import ConfigDB from "./configs/config";
import Login from "./pages/auth/login";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Root = (props) => {

    const [anim, setAnim] = useState("");
    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
    const abortController = new AbortController();

    useEffect(() => {
        console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
        console.disableYellowBox = true;
        return function cleanup() {
            abortController.abort();
        }
    }, [abortController, animation]);

    return (
        <Fragment>
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollToTop/>
                    <Switch>
                        <Route
                            path={`/login`}
                            component={Login}
                        />

                        <App>
                            <Route
                                exact
                                path={`/`}
                                render={() => {
                                    return (
                                        <Redirect
                                            to={`/`}
                                        />
                                    );
                                }}
                            />

                            <TransitionGroup>
                                {routes.map(({path, Component}) => (
                                    <Route
                                        key={path}
                                        exact
                                        path={`${path}`}
                                    >
                                        {({match}) => (
                                            <CSSTransition
                                                in={match != null}
                                                timeout={500}
                                                classNames={anim}
                                                unmountOnExit
                                            >
                                                <div>
                                                    <Component/>
                                                </div>
                                            </CSSTransition>
                                        )}
                                    </Route>
                                ))}
                            </TransitionGroup>
                        </App>
                    </Switch>
                </BrowserRouter>
            </Provider>
            <ToastContainer />
        </Fragment>
    );
};
ReactDOM.render(<Root/>, document.getElementById("root"));
serviceWorker.unregister();
