import {HttpClient} from "../api";

export class Api extends HttpClient {

    constructor() {
        super('https://server.abrvt.com/administration');
    }

    route(routeName, routeParams = {}) {
        const routes = [
            {
                name: 'login',
                path: '/auth/login'
            },
            {
                name: 'check',
                path: '/auth/check'
            },
            {
                name: 'change password',
                path: '/auth/change-password'
            },
            {
                name: 'store user',
                path: '/users/store'
            },
            {
                name: 'all user',
                path: '/users'
            },
            {
                name: 'update user',
                path: '/users/update/' + routeParams.id
            },
            {
                name: 'detail user',
                path: '/users/detail/' + routeParams.id
            },
            {
                name: 'codes list',
                path: '/codes/all/' + routeParams.page
            },
            {
                name: 'codes search',
                path: '/codes/search'
            },
            {
                name: 'codes all list',
                path: '/codes/export'
            },
            {
                name: 'dashboard',
                path: '/dashboard'
            },
        ]

        let returnRoute = routes.find(route => route.name === routeName).path

        for (const [key, value] of Object.entries(routeParams)) {
            returnRoute = returnRoute.replace(':' + key, value)
        }

        return returnRoute
    }
}
