import {Api} from "../../routes";

export const UserService = {
    store: (name, sureName, mobile, password, isActive, role) => new Promise((resolve, reject) => {
        const api = new Api();

        api.instance.post(api.route('store user'), {
            name: name,
            sureName: sureName,
            mobile: mobile,
            password: password,
            isActive: isActive,
            role: role
        }, {headers: {'Content-Type': 'application/json'}}).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    all: () => new Promise((resolve, reject) => {
        const api = new Api();

        api.instance.get(api.route('all user')).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    getDetail: (id) => new Promise((resolve, reject) => {
        const api = new Api();

        api.instance.get(api.route('detail user', {id: id})).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

    update: (id, name, sureName, mobile, password, isActive, role) => new Promise((resolve, reject) => {
        const api = new Api();

        api.instance.post(api.route('update user', {id: id}), {
            name: name,
            sureName: sureName,
            mobile: mobile,
            password: password,
            isActive: isActive,
            role: role
        }, {headers: {'Content-Type': 'application/json'}}).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),

}
