import {withRouter} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Label, Row} from "reactstrap";
import {UserService} from "../../api";
import {CButton, CTextInput} from "../../components/common/Custom";
import validator from "validator";
import {toast} from "react-toastify";
import swal from "sweetalert";
import CSelect from "../../components/common/Custom/select";

const Createuser = (props) => {

    const [buttonLoading, setButtonLoading] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [firstnameError, setFirstnameError] = useState('')
    const handleSetFirstname = event => {
        setFirstnameError('')
        if (validator.isEmpty(event.target.value))
            setFirstnameError('Name')
        else if (event.target.value.length > 255)
            setFirstnameError('Name is invalid');

        setFirstname(event.target.value)
    }

    const [lastname, setLastname] = useState('')
    const [lastnameError, setLastnameError] = useState('')
    const handleSetLastname = event => {
        setLastnameError('')
        if (validator.isEmpty(event.target.value))
            setLastnameError('Sure name is required')
        else if (event.target.value.length > 255)
            setLastnameError('Sure name is invalid');

        setLastname(event.target.value)
    }

    const [mobile, setMobile] = useState('')
    const [mobileError, setMobileError] = useState('')
    const handleSetMobile = event => {
        setMobileError('')
        if (validator.isEmpty(event.target.value))
            setMobileError('Mobile is required')

        setMobile(event.target.value)
    }

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const handleSetPassword = event => {
        setMobileError('')
        if (validator.isEmpty(event.target.value))
            setPasswordError('password is required')

        setPassword(event.target.value)
    }

    const [role, setRole] = useState('')
    const [roleError, setRoleError] = useState('')
    const handleSetRole = (value) => {
        setRoleError('')
        setRole(value.replaceAll('"', ''))
    }

    const [active, setActive] = useState(true)
    const [activeError, setActiveError] = useState('')
    const handleSetActive = (value) => {
        setActiveError('')
        setActive(value.replaceAll('"', '') === 'true')
    }

    const createUser = event => {
        event.preventDefault()

        var goOn = true
        setButtonLoading(true)

        setFirstnameError('')
        setLastnameError('')
        setMobileError('')
        setPasswordError('')
        setRoleError('')


        if (validator.isEmpty(firstname)) {
            setFirstnameError('Name is required')
            goOn = false
        } else if (firstname.length > 255) {
            setFirstnameError('Name is invalid');
            goOn = false
        }

        if (validator.isEmpty(lastname)) {
            setLastnameError('Sure name is required')
            goOn = false
        } else if (lastname.length > 255) {
            setLastnameError('Sure name is invalid');
            goOn = false
        }

        if (validator.isEmpty(mobile)) {
            setMobileError('Mobile is required')
            goOn = false
        }

        if (validator.isEmpty(role)) {
            setRoleError('Select role is required')
            goOn = false
        }

        if (!goOn) {
            setButtonLoading(false)
        } else {
            UserService.store(firstname, lastname, mobile, password, active, role).then(result => {
                setButtonLoading(false)
                if (result.success) {
                    swal({
                        title: result.message,
                        icon: "success",
                        button: 'OK',
                    }).then(() => {
                        props.history.push(`/users`);
                    })
                } else {
                    toast.error(result.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch(error => {
                setButtonLoading(false)
                swal({
                    title: JSON.parse(JSON.stringify(error)).message,
                    icon: "success",
                    button: 'OK',
                }).then(() => {
                    if (JSON.parse(JSON.stringify(error)).status === 403) {
                        props.history.push('/error/403')
                    } else if (JSON.parse(JSON.stringify(error)).status === 401) {
                        props.history.push('/error/401')
                    } else if (JSON.parse(JSON.stringify(error)).status === 400) {
                        props.history.push('/error/400')
                    } else {
                        props.history.push('/error/500')
                    }
                })
            })
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent={'Admin Panel'} title={'Create user'}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5>{'Create user'}</h5>
                            </CardHeader>
                            <Form className="form theme-form"
                                  onSubmit={createUser}>
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'firstname'}
                                                title={'Name'}
                                                onChange={handleSetFirstname}
                                                error={firstnameError}
                                                value={firstname}/>
                                        </Col>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'lastname'}
                                                title={'Sure name'}
                                                onChange={handleSetLastname}
                                                error={lastnameError}
                                                value={lastname}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'mobile'}
                                                title={'Mobile'}
                                                onChange={handleSetMobile}
                                                error={mobileError}
                                                value={mobile}/>
                                        </Col>
                                        <Col sm={6}>
                                            <CTextInput
                                                id={'password'}
                                                title={'Password'}
                                                onChange={handleSetPassword}
                                                error={passwordError}
                                                value={password}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CSelect
                                                id={'Role'}
                                                title={'Role'}
                                                selected={role}
                                                options={[{name: 'Admin', value: 'admin'}, {name: 'Parking manager', value: 'parking-manager'}, {name: 'Curbside team', value: 'curbside-team'}]}
                                                onChange={handleSetRole}
                                                error={roleError}/>
                                        </Col>
                                        <Col sm={6}>
                                            <CSelect
                                                id={'isActive'}
                                                title={'Activation'}
                                                selected={active}
                                                options={[{name: 'Active', value: true}, {name: 'Deactivated', value: false}]}
                                                onChange={handleSetActive}
                                                error={activeError}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <CButton
                                        title={'Create'}
                                        color={'primary'}
                                        loading={buttonLoading}
                                        className={'btn-block'}/>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withRouter(Createuser)