import { combineReducers } from "redux";
import {AdminReducer} from "./reducers";
import Customizer from "./reducers/customizer";

const reducers = combineReducers({
    Customizer,
    AdminReducer
});

export default reducers;
