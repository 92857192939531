import React, { Fragment } from "react";
import sad from "../../assets/images/other-images/sad.png";
import { Link } from "react-router-dom";
import { Container, Button, Media, Col } from "reactstrap";

const Error403 = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- error-400 start--> */}
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <h2 className="headline font-info">403</h2>
                        </div>
                        <Col md="8 offset-md-2">
                            <p className="sub-content">
                              Access denied
                            </p>
                        </Col>
                    </Container>
                </div>
                {/* <!-- error-400 end--> */}
            </div>
        </Fragment>
    );
};

export default Error403;
