import React from "react";

export const MENUITEMS = [
    {
        title: "Dashboard",
        icon: <i className="pe-7s-home pe-lg"></i>,
        path: `/dashboard`,
        type: "link",
        active: true,
        bookmark: true,
    },
    {
        title: "Users",
        icon: <i className="pe-7s-browser pe-lg"></i>,
        type: "sub",
        active: false,
        bookmark: true,
        children: [
            {
                title: "New user",
                type: "link",
                path: `/users/create`,
            },
            {
                title: "Users",
                type: "link",
                path: `/users`,
            },
        ],
    },
    {
        title: "QR Codes",
        icon: <i className="pe-7s-note pe-lg"></i>,
        type: "sub",
        active: false,
        bookmark: true,
        children: [
            {
                title: "Lists",
                type: "link",
                path: `/codes`,
            },
            {
                title: "Search",
                type: "link",
                path: `/codes/search`,
            },
        ],
    },
];
