import {Api} from "../../routes";

export const DashboardService = {

    dashboard: () => new Promise((resolve, reject) => {
        const api = new Api();

        api.instance.get(api.route('dashboard')).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    }),


}
