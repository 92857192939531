import {FormGroup, Input, Label} from "reactstrap";
import React from "react";


const CSelect = (props) => {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>
                {props.title}
            </Label>
            <select multiple={props.multiple || false} className={`form-control ${props.error ? 'border-danger' : ''}`}
                    name={props.name}
                    id={props.id}
                    onChange={(e) => {
                        props.multiple ? props.onChange(e.target.value) : props.onChange(JSON.stringify(e.target.value))
                    }}>
                <option value="">Select an option</option>
                {props.options.map((option, index) =>
                    <option value={option.value || option.id}
                            selected={(option.value || option.id) === props.selected}>{option.name || option.title}</option>
                )}
            </select>

            <small className="text-danger">{props.error}</small>
        </FormGroup>
    )
}

export default CSelect;

