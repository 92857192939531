import React, {useCallback} from "react";
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';
import {ReactComponent as DropZoneIcon} from '../../src/assets/images/svg/posts.svg';

const Wrapper = styled.div`
  width: 100%;
  height: ${({fullHeight}) => fullHeight ? '100%' : 'auto'};
  background: ${({theme}) => theme.bodyLight};
  border-radius: 15px;
  border: 2px dashed ${({theme}) => theme.border};
  padding: 20px;
  text-align: center;
  
  & p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: ${({theme}) => theme.placeholder}
  }
  
  & svg {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px auto;
    fill: ${({theme}) => theme.placeholder}
  }
  
  &:hover {
    cursor: pointer;
  }
`

function DropZone(props) {

    const {theme, mimeTypes, maxFiles, maxSize, multiple, callback, fullHeight, ...other} = props
    let selectedFile = false
    const onDropAccepted = useCallback(acceptedFiles => {
        callback(acceptedFiles)
        selectedFile = true
        // console.log(acceptedFiles)
    }, [callback])

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        accept: mimeTypes,
        maxFiles: maxFiles ?? 1,
        maxSize: maxSize,
        multiple: multiple ?? false,
        onDropAccepted: onDropAccepted
    });

    return (
        <Wrapper fullHeight={fullHeight ?? false}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {selectedFile ?
                    <p>فایل انتخاب گردید</p>
                    :
                    isDragActive ?
                        <>
                            <DropZoneIcon/>
                            <p>فایل را در اینجا رها نمایید</p>
                        </>
                        :
                        <>
                            <DropZoneIcon/>
                            <p>کلیک کنید و یا فایل ها را دراینجا رها کنید</p>
                        </>
                }
            </div>
        </Wrapper>
    )
}

export default DropZone
