import Dashboard from "../pages";
import Login from "../pages/auth/login";
import Error403 from "../pages/errors/error403";
import Error400 from "../pages/errors/error400";
import Error401 from "../pages/errors/error401";
import Error500 from "../pages/errors/error500";
import Createuser from "../pages/users/create";
import UserList from "../pages/users/list";
import UpdateUser from "../pages/users/update";
import CodesList from "../pages/codes/list";
import CodesSearch from "../pages/codes/search";
import EditAdminProfile from "../pages/profile";

export const routes = [

  { path: "/error/403", Component: Error403 },
  { path: "/error/400", Component: Error400 },
  { path: "/error/401", Component: Error401 },
  { path: "/error/500", Component: Error500 },

  { path: "/login", Component: Login },

  { path: "/", Component: Dashboard },
  { path: "/dashboard", Component: Dashboard },

  { path: "/users/create", Component: Createuser },
  { path: "/users", Component: UserList },
  { path: "/users/:id/edit", Component: UpdateUser },

  { path: "/codes", Component: CodesList },
  { path: "/codes/search", Component: CodesSearch },

  { path: "/profile", Component: EditAdminProfile },
];
