import {Tooltip} from "@mui/material";
import styled from "styled-components";
import Fade from '@mui/material/Fade';
import React from "react";

const CTooltipStyle = styled(Tooltip)`
  
`

export function CTooltip(props) {
    const {children, ...other} = props

    return (
        <CTooltipStyle
            TransitionComponent={Fade}
            {...other}
        >
            {children}
        </CTooltipStyle>
    )
}