import {Button} from "@mui/material";
import styled from "styled-components";
import React from "react";

const CButtonStyle = styled(Button)`
  && {
    box-shadow: none;
    font-family: openSans;
    font-size: 14px;
    font-weight: 500;
    background: ${({theme, color, variant}) => variant === 'outlined' ? 'transparent' : theme[color]};
    color: ${({theme, color, variant}) => theme[variant === 'outlined' ? color : color+'Text']};
    border-radius: 7px;
    border: 1px solid ${({theme, color}) => theme[color]};
    transition: all .05s ease-in-out;
    
    &:hover {
      background: ${({theme, color, variant}) => theme[variant === 'outlined' ? color : color+'Light']};
      border: 1px solid ${({theme, color, variant}) => theme[variant === 'outlined' ? color : color+'Light']};
      transition: all .05s ease-in-out;
    }
  }
  &&.Mui-disabled {
    background: ${({theme, color}) => theme[color+'Slight']};
    border: 1px solid ${({theme, color}) => theme[color+'Slight']};
    &:hover {
      cursor: not-allowed;
    }
  }
`

export function CButton(props) {
    const {...other} = props

    return (
        <CButtonStyle
            {...other}
            disableRipple={true}
        >
            {props.children}
        </CButtonStyle>
    )
}