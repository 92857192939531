import React, {
    useState,
    useEffect,
    useLayoutEffect,
} from "react";
import man from "../../../assets/images/dashboard/user.png";
import {
    AlignCenter,
    User,
    Clipboard,
    LogOut,
    Maximize,
    MoreHorizontal, DollarSign, List, Monitor, Key, ThumbsUp, MessageCircle,
} from "react-feather";
import {Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";

const Header = (props) => {
    const [profile, setProfile] = useState("");
    const [navmenu, setNavmenu] = useState(false);
    const [sidebar, setSidebar] = useState("iconsidebar-menu");
    const width = useWindowSize();

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }

            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    useEffect(() => {
        // useWindowSize();
        if (width < 991) {
            setSidebar("iconbar-second-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.add("iconbar-second-close");
        } else {
            setSidebar("iconsidebar-menu");
            document
                .querySelector(".iconsidebar-menu")
                .classList.remove("iconbar-second-close");
        }

        setProfile(localStorage.getItem("profileURL") || man);

    }, [width]);

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("admin");

        window.location.href = `/login`
    };


    const openCloseSidebar = (sidebartoggle) => {
        if (sidebartoggle === "iconsidebar-menu") {
            setSidebar("iconbar-mainmenu-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.add("iconbar-mainmenu-close");
        } else if (sidebartoggle === "iconbar-mainmenu-close") {
            setSidebar("iconbar-second-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.add("iconbar-second-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.remove("iconbar-mainmenu-close");
        } else {
            setSidebar("iconsidebar-menu");
            document
                .querySelector(".iconsidebar-menu")
                .classList.remove("iconbar-second-close");
        }
    };

    //full screen function
    const goFull = () => {
        if (
            (document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    const Navmenuhideandshow = () => {
        if (navmenu) {
            setNavmenu(!navmenu);
            document.querySelector(".nav-menus").classList.add("open");
        } else {
            setNavmenu(!navmenu);
            document.querySelector(".nav-menus").classList.remove("open");
        }
    };

    return (
        <div className="page-main-header">
            <div className="main-header-right">
                <div className="main-header-left text-center">
                    <div className="logo-wrapper">
                        <Link to={``}>
                            <img
                                src={require("../../../assets/images/logo/logo.png")}
                                alt=""
                                width={50}
                            />
                        </Link>
                    </div>
                </div>
                <div className="mobile-sidebar">
                    <div className="media-body text-right switch-sm">
                        <label className="switch ml-3">
                            <AlignCenter
                                className="font-primary"
                                onClick={() => openCloseSidebar(sidebar)}
                            />
                        </label>
                    </div>
                </div>
                <div className="nav-right col pull-right right-menu">
                    <ul className="nav-menus">
                      <li></li>
                        <li>
                            <a className="text-dark" href="#javascript!" onClick={goFull}>
                                <Maximize/>
                            </a>
                        </li>
                        <li className="onhover-dropdown">

                        </li>
                        <li className="onhover-dropdown">

                        </li>
                        <li></li>
                        <li className="onhover-dropdown">
                            {" "}
                            <span className="media user-header">
                                <img
                                    className={profile === man ? "img-fluid" : "otheruser"}
                                    src={profile}
                                    alt=""
                                />
                            </span>
                            <ul className="onhover-show-div profile-dropdown">
                                {localStorage.getItem('admin') ?
                                    <li className="gradient-primary">
                                        {/*<h5 className="f-w-600 mb-0">{JSON.parse(localStorage.getItem('admin')).name + ' ' + JSON.parse(localStorage.getItem('admin')).family}</h5>*/}
                                        {/*<span>{JSON.parse(localStorage.getItem('admin')).role.title}</span>*/}
                                    </li>
                                    : null}
                                <li onClick={() => {
                                    props.history.push({
                                        pathname: `/profile`
                                    })
                                }}>
                                    <User/>
                                    Profile
                                </li>
                                <li onClick={logOut}>
                                    <LogOut/>
                                    Logout
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div
                        className="d-lg-none mobile-toggle pull-right"
                        onClick={Navmenuhideandshow}
                    >
                        <MoreHorizontal/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Header);
